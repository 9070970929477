import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import Loader from 'Component/Loader';
import Field from 'Component/Field';
import { SHIPPING_STEP } from 'Route/Checkout/Checkout.config';
import FormPortal from 'Component/FormPortal';

import './ParcelshopPicker.override.style.scss';
import FieldForm from 'Component/FieldForm/FieldForm.component';

export class ParcelshopPicker extends PureComponent {
    static propTypes = {
        onSelect: PropTypes.func.isRequired
    }
    __construct(props) {
        super.__construct(props);
    }

    renderShop(shop){
        const { onSelect } = this.props;
        const { name, id } = shop;
        return (
            <li block="ParcelshopPicker" elem="ShopItem" key={id}>
                <button block="ParcelshopPicker" elem="ItemButton" onClick={() => onSelect(shop)}>{name}</button>
            </li>
        );
    }

    onChange = (id) => {
        const { onSelect, shops, options } = this.props;
        const option = options.find((shop) => shop.id === id);
        const shop = shops.find((shop) => shop.id === id);
        shop.label = option.label;
        onSelect(shop);
    }

    renderOptions(){
        const { options } = this.props;
        return (
            <Field
                id="parcelshop_picker"
                name="parcelshop_picker"
                type="select"
                label={__('Pickup location')}
                mix={{ block: 'ParcelshopPicker', elem: 'Select' }}
                selectOptions={options}
                onChange={this.onChange}
                keepOrder={true}
                validation={['notEmpty']}
            />
        );
    }

    renderZipInput() {
        const { postcode, onPostChange } = this.props;
        return (
            <Field
                type="text"
                label={__('Zip/Postal code')}
                id="pickup_options_zip_code"
                name="pickup_options_zip_code"
                value={postcode}
                onChange={onPostChange}
                validation={['notEmpty']}
            />
        );
    }

    render() {
        const { active, isLoading } = this.props;
        if (!active) {
            return null;
        }

        return (
            <div block="ParcelshopPicker">
                <Loader isLoading={isLoading} />
                <h2 block="ParcelshopPicker" elem="Heading">{__('Select pickup location')}</h2>
                <div block="ParcelshopPicker" elem="Inputs">
                    <FormPortal
                        id={SHIPPING_STEP}
                        name="ParcelshopPicker"
                    >
                        {this.renderZipInput()}
                        {this.renderOptions()}
                    </FormPortal>
                </div>
            </div>
        );
    }
}

export default ParcelshopPicker;