
const keepSortingOrder = (args, callback, instance) => {
    const { selectOptions, keepOrder } = instance.props;
    if (keepOrder){
        return selectOptions;
    }
    return callback(...args);
}

export default {
    'Component/FieldSelect/Container': {
        'member-function': {
            sortSelectOptions: keepSortingOrder
        }
    }
};