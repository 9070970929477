import { Field } from 'Util/Query';

/** @namespace Hoeks/Parcelshop/Query/Parcelshops */
export class ParcelshopsQuery {
    getParcelshopsQuery(shippingMethod) {
        // const { method, carrier, postcode } = shippingMethod;
        const query = new Field('hks_getparcelshops')
            .addFieldList(this._getShops())
            .setAlias('shops');
        query.addArgument('input', 'ParcelshopsInput!', shippingMethod);
        return query;
    }

    _getShops() {
        return [
            new Field('shops')
            .addFieldList(this._getParcelshopsFields())];
    }

    _getParcelshopsFields(){
        return [
            'id',
            'name',
            'address',
            'zipcode',
            'city',
            'country',
            'distance',
            'longitude',
            'latitude',
            'opening_hours'
        ];
    }
}




export default new ParcelshopsQuery();
