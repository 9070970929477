/* eslint-disable */
import PropTypes from 'prop-types';
import { updateNoMatch } from 'Store/NoMatch/NoMatch.action';

export const NoMatchDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/NoMatch/NoMatch.dispatcher'
);

const onSuccess = (args, callback, instance) => {
    const [ { urlResolver }, dispatch ] = args;
    callback.apply(instance, args);
    // dispatch(updateNoMatch(!urlResolver));
};

const onError = (args, callback, instance) => {
    const [ _, dispatch ] = args;
    callback.apply(instance, args);
    dispatch(updateNoMatch(true));
};

const mapDispatchToProps = (args, callback, instance) => {
    const [ dispatch ] = args;
    return {
        ...callback.apply(instance, args),
        updateNoMatch: (options) => NoMatchDispatcher.then(
            ({ default: dispatcher }) => dispatcher.updateNoMatch(dispatch, options)
        )
    }
};

const propTypes = (originalMember) => ({
    ...originalMember,
    updateNoMatch: PropTypes.func.isRequired,
    onClick: PropTypes.func
});

const defaultProps = (originalMember) => ({
    ...originalMember,
    onClick: () => {}
});

const containerFunctions = (originalMember, instance) => ({
    ...originalMember,
    onClick: instance.onClick.bind(instance)
});

const onClick = (args, callback, instance) => {
    const [ e ] = args;
    const { updateNoMatch, onClick } = instance.props;
    updateNoMatch(false);

    if (onClick) {
        onClick(e);
    }
};

const render = (args, callback, instance) => {
    const result = callback.apply(instance, args);

    if (!result || !React.isValidElement(result)) {
        return result;
    }

    return React.cloneElement(result, { ...instance.containerFunctions });
}

export default {
    'Store/UrlRewrites/Dispatcher': {
        'member-function': {
            onSuccess,
            onError
        }
    },
    'Component/Link/Container/mapDispatchToProps': {
        'function': mapDispatchToProps
    },
    'Component/Link/Container': {
        'static-member': {
            propTypes,
            defaultProps
        },
        'member-property': {
            containerFunctions
        },
        'member-function': {
            onClick,
            render
        }
    }
}