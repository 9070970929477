/* eslint-disable no-magic-numbers */
/* eslint-disable object-curly-spacing */
/* eslint-disable react/jsx-indent-props */
import { NavigationTabs as HoeksNavigationTabs } from 'HoeksComponent/NavigationTabs/NavigationTabs.component';

import React from 'react';
import Logo from 'Component/Logo';
import CSS from 'Util/CSS';
import media from 'Util/Media';
import { LOGO_MEDIA } from 'Util/Media/Media';

import './NavigationTabs.override.style';

export * from 'HoeksComponent/NavigationTabs/NavigationTabs.component';

export class NavigationTabs extends HoeksNavigationTabs {
    renderMap = {
        home: this.renderHomeButton.bind(this),
        search: this.renderSearchButton.bind(this),
        account: this.renderAccountButton.bind(this),
        minicart: this.renderMinicartButton.bind(this),
        menu: this.renderMenuButton.bind(this)
    };

    renderLogoImage() {
        const {
            header_logo_src,
            logo_alt,
            logo_height,
            logo_width
        } = this.props;

        // if no src defined from the backend, pass null in order to display placeholder
        // and prevent unnecessary load of corrupted resource
        // const logoSrc = header_logo_src ? media(header_logo_src, LOGO_MEDIA) : null;

        // NOTE(bjarne): I made their SVG logo be the fallback, so they can change it themselves later in the store admin if they want.
        const logoSrc = header_logo_src ? media(header_logo_src, LOGO_MEDIA) : 'https://www.berrybean.dk/pub/media/hoeks-pwa/logo/Berry_Bean_Logo_Black.svg';
        const logoAlt = header_logo_src ? logo_alt : __('Berry&Bean logo');
        const logoHeight = header_logo_src ? logo_height : 40;
        const logoWidth = header_logo_src ? logo_width : 245;

        CSS.setVariable(this.logoRef, 'header-logo-height', `${logoHeight}px`);
        CSS.setVariable(this.logoRef, 'header-logo-width', `${logoWidth}px`);

        return (
            <Logo
              src={ logoSrc }
              alt={ logoAlt }
              title={ logoAlt }
            />
        );
    }

    renderHomeButton(isActive = false) {
        const { onHomeButtonClick } = this.props;

        return (
            <button
                key="home"
                block="NavigationTabs"
                elem="Button"
                aria-label="Home"
                onClick={ onHomeButtonClick }
                className="NavigationTabs-LogoBtn"
            >
                <div
                    block="Header"
                    elem="Button"
                    mix={ { block: 'NavigationTabs', elem: 'Icon', mods: { isActive } } }
                    mods={ { type: 'home', isVisible: true } }
                    className="NavigationTabs-LogoWrapper"
                >
                    { this.renderLogoImage() }
                </div>
            </button>
        );
    }

    renderSearchButton(_) {
        const { onMenuButtonClick, showMenu = false } = this.props;
        const isActive = showMenu === true;

        return (
            <React.Fragment key="menu">
                <button
                    key="menu"
                    block="NavigationTabs"
                    elem="Button"
                    aria-label="Go to menu and search"
                    onClick={ onMenuButtonClick }
                >
                    <div
                        block="Header"
                        elem="Button"
                        mix={ { block: 'NavigationTabs', elem: 'Icon', mods: { isActive } } }
                        mods={ { isVisible: true, type: 'search' } }
                    />
                </button>
            </React.Fragment>

        );
    }
}

export default NavigationTabs;
