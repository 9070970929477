import BrowserDatabase from 'Util/BrowserDatabase';

/**
 *
 * @param {*} content
 */
export const report = (content) => {
    const quoteId = BrowserDatabase.getItem('guest_quote_id');
    const customer = BrowserDatabase.getItem('customer');
    /* send error info to server */
    const xhr = new XMLHttpRequest();
    xhr.open('POST', '/hoeks/exception/logging', true);
    xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
    xhr.send(JSON.stringify({
        location: window.location.href, content, quoteId, customer
    }));
};
