/* eslint-disable */
import { Route } from 'react-router-dom';
import { SWITCH_ITEMS_TYPE } from 'Component/Router/Router.config';
import { withStoreRegex } from 'Component/Router/Router.component';
import EpayGateway from '../route/EpayGateway';

const addEpayRoutes = (member, _) => {
    return [
        ...member,
        {
            component: <Route path={ withStoreRegex('/epay/:resolution') } exact render={ (props) => <EpayGateway { ...props } /> } />,
            position: 100
        }
    ]
}

export default {
    'Component/Router/Component': {
        'member-property': {
            [SWITCH_ITEMS_TYPE]: addEpayRoutes
        }
    }
}