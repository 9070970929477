/* eslint-disable */
// import PropTypes from 'prop-types';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import SubscriptionActions from './SubscriptionActions.component';

/** @namespace Hoeks/Subscription/Component/SubscriptionActions/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    subscriptionIntervals: state.ConfigReducer.manage_subscription_interval,
    subscriptionDescriptionUrl: state.ConfigReducer.subscription_description_url
});

/** @namespace Hoeks/Subscription/Component/SubscriptionActions/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({
});

/** @namespace Hoeks/Subscription/Component/SubscriptionActions/Container/SubscriptionActionsContainer */
export class SubscriptionActionsContainer extends PureComponent {
    static propTypes = {

    };

    state = {
        acceptedSubscription: false,
        acceptedOneTimePayment: true,
        selectedOption: ''
    }

    subscriptionOptionsRef = React.createRef();

    containerFunctions = {
        // getData: this.getData.bind(this)
        onSubscriptionAccept: this.onSubscriptionAccept.bind(this),
        onOneTimePaymentAccept: this.onOneTimePaymentAccept.bind(this),
        onFieldSelectChanged: this.onFieldSelectChanged.bind(this),
        prepareSelectOptionsData: this.prepareSelectOptionsData.bind(this)
    };

    containerProps = () => {
        // isDisabled: this._getIsDisabled()
    };
    componentDidMount() {
        const {subscriptionIntervalChange, subscriptionIntervals} = this.props;
        if (subscriptionIntervals == undefined) return;
        if (subscriptionIntervals.length) {
            subscriptionIntervalChange('default');
            // this.setState({selectedOption: subscriptionIntervals[0].key});
        }
    }
    componentDidUpdate() {
        const {subscriptionIntervalChange, subscriptionIntervals} = this.props;
        const {selectedOption} = this.state;
        // if (subscriptionIntervals.length && !selectedOption) {
        //     subscriptionIntervalChange(subscriptionIntervals[0].key);
        //     // this.setState({selectedOption: subscriptionIntervals[0].key});
        // }
    }
    //Checkbox
    onSubscriptionAccept(value, checked) {
        const { changeSubscription, subscriptionIntervals } = this.props;

        this.hideSubscriptionOptions(checked);
        changeSubscription(true, 'default');
        this.setState({acceptedSubscription: true, acceptedOneTimePayment: false});
    }

    onOneTimePaymentAccept(value, checked) {
        const { changeSubscription } = this.props;
        this.hideSubscriptionOptions(false);
        changeSubscription(false, '');
        this.setState({acceptedOneTimePayment: true, acceptedSubscription: false});
    }

    hideSubscriptionOptions(checked) {
        if (this.subscriptionOptionsRef) {
            if (checked) {
                this.subscriptionOptionsRef.style.display = 'block'
            } else {
                this.subscriptionOptionsRef.style.display = 'none';
            }
        }
    }

    //Field Select
    onFieldSelectChanged(selctedValue) {
        const { subscriptionIntervalChange } = this.props;
        subscriptionIntervalChange(selctedValue);
    }

    prepareSelectOptionsData() {
        const {subscriptionIntervals} = this.props;
        var selectOptions = [];
        if (subscriptionIntervals == undefined) {
            let option = {
                id: '',
                value: '',
                disable: false,
                label: ''
            }
            selectOptions.push(option);
        } else {
            //add Default value
            selectOptions.push({
                id: 'default',
                value: 'default',
                disable: false,
                label: __('Please Select a Billing Cycle')
            });

            subscriptionIntervals.forEach(({key, interval_label}) => {
                let option = {
                    id: key,
                    value: key,
                    disable: false,
                    label: interval_label
                }
                selectOptions.push(option);
            });
        }

        return selectOptions;
    }

    render() {
        return (
            <SubscriptionActions
                { ...this.props }
                { ...this.state }
                { ...this.containerFunctions }
                { ...this.containerProps() }
                subscriptionOptionsRef={ ref => this.subscriptionOptionsRef = ref }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionActionsContainer);
