/* eslint-disable */
import { ProductCard as SourceProductCard } from 'SourceComponent/ProductCard/ProductCard.component';

import TextPlaceholder from 'Component/TextPlaceholder';
import Html from 'Component/Html';
import ProductPrice from 'Component/ProductPrice';
import { BUNDLE, CONFIGURABLE, GROUPED } from 'Util/Product';
import { GRID_LAYOUT, LIST_LAYOUT } from 'Route/CategoryPage/CategoryPage.config';
import Loader from 'Component/Loader';
import AddToCart from 'Component/AddToCart';

import './ProductCard.override.style';

export class ProductCard extends SourceProductCard {
    // renderProductCardWishlistButton() {
    //     return (
    //         null
    //     );
    // }

    renderAddToCart() {
        const {
            product,
            product: {
                type_id,
                stock_status
            }
        } = this.props;
        const configurableVariantIndex = -1;
        const quantity = 1;
        const groupedProductQuantity = {};
        const productOptionsData = {};

        if (type_id !== 'simple' || stock_status === 'OUT_OF_STOCK' ) {
            return this.renderCardLinkWrapper(
                <button block="Button">{ __('View Product') }</button>,{ block: 'ProductViewMore' }
            );
        }

        return (
            <AddToCart
              product={ product }
              configurableVariantIndex={ configurableVariantIndex }
              mix={ { block: 'ProductActions', elem: 'AddToCart' } }
              quantity={ quantity }
              groupedProductQuantity={ groupedProductQuantity }
              productOptionsData={ productOptionsData }
            />
        );
    }

    renderProductPrice() {
        const {
            product: { price_range, type_id },
            isConfigurableProductOutOfStock,
            isBundleProductOutOfStock
        } = this.props;

        if (!price_range) {
            return <TextPlaceholder />;
        }

        switch (type_id) {
        case CONFIGURABLE:
            if (isConfigurableProductOutOfStock()) {
                return this.renderEmptyProductPrice();
            }
            break;
        case BUNDLE:
            if (isBundleProductOutOfStock()) {
                return this.renderEmptyProductPrice();
            }
            break;
        default:
            break;
        }

        return (
            <div block="ProductCard" elem="PriceWrapper">
                { this.renderTierPrice() }
                { this.renderProductTypePriceBadge() }
                <p>
                    {type_id == CONFIGURABLE ? <span block="ProductCard" elem="FromPrice">{__('From:')}</span> : <></>}
                    <ProductPrice
                      price={ price_range }
                      mix={ { block: 'ProductCard', elem: 'Price', mods: { isConfig: type_id == CONFIGURABLE } } }
                    />
                </p>
            </div>
        );
    }

    renderBadges() {
        const {
            product: {
                attributes: {
                    product_badges: {
                        attribute_options = {}
                    } = {}
                } = {},
                stock_status
            } = {},
            productHasSpecialPrice,
            productIsInStock,
            productIsOkologisk
        } = this.props;

        // multiselect badges
        let badgesAttr = false;
        const badgesToRender = [];

        if (Object.keys(attribute_options).length != 0) {
            badgesAttr = attribute_options;
        } else {
            badgesAttr = {};
        }

        for (var key of Object.keys(badgesAttr)) {
            if (badgesAttr[key]?.label == 'newproduct') {
                badgesToRender.push({
                    label: __('New Product'),
                    class: 'BadgeNewProduct'
                })
            }
            if (badgesAttr[key]?.label == 'monthscoffee') {
                badgesToRender.push({
                    label: __('Coffee of the Month'),
                    class: 'BadgeMonthsCoffee'
                })
            }
            if (badgesAttr[key]?.label == 'roastprofile') {
                badgesToRender.push({
                    label: __('New Roast Profile'),
                    class: 'BadgeRoastProfile'
                })
            }
            if (badgesAttr[key]?.label == 'restsalg') {
                badgesToRender.push({
                    label: __('Clearance'),
                    class: 'RestSalg'
                })
            }
            if (badgesAttr[key]?.label == 'bestillingsvare') {
                badgesToRender.push({
                    label: __('On Order Goods'),
                    class: 'BadgeOnOrderGoods'
                })
            }
        }

        // special price badge 
        if (productHasSpecialPrice()) {
            badgesToRender.push({
                label: __('On Sale'),
                class: 'SpecialPrice'
            })
        }

        // console.log(productIsInStock);
        if (stock_status && !productIsInStock) {
            badgesToRender.push({
                label: __('Out of Stock'),
                class: 'NoStock'
            })
        }

        // con_okologisk badge 
        if (productIsOkologisk()) {
            badgesToRender.push({
                label: __('Økologisk'),
                class: 'BadgeOkologisk'
            })
        }

        return (
            <div block="ProductCard" elem="Badges">
                {badgesToRender.map((badge) => <div block="ProductCard" elem={"Badge " + badge.class}>{badge.label}</div>)}
            </div>
        )
    }

    renderPriceRunnerImage() {
        const {product: {sku = ""}} = this.props;
        const src = `https://pricerunner.dk/widget/cheapest/image/65439?sku=${sku}`;
        return (
            <div block="ProductCard" elem="PriceRunner">
                <img src={src}/>
            </div>
        );
    }

    renderAdditionalProductDetails() {
        return (
            <div block="ProductCard" elem="Type">
                <TextPlaceholder content="Kaffebønner" />
            </div>
        );
    }

    renderMainDetails() {
        const {
            product,
            product: { name },
            product: { attributes: { con_no: { attribute_options : coffeeNumber = {} } = {} } = {} } = {}
        } = this.props;

        let numberString = Object.keys(coffeeNumber).map((key) => (
            coffeeNumber[key].label
        )).join('');

        if (numberString || !numberString === '') {
            numberString = `NO°${numberString} `;
        }

        return this.renderCardLinkWrapper(
            <p
              block="ProductCard"
              elem="Name"
              mods={ { isLoaded: !!name } }
            >
                <span block="ProductCard" elem="CoffeeNumber">{ numberString }</span>
                <TextPlaceholder content={ name } length="medium" />
            </p>
        );
    }

    renderShortDescription() {
        //const { product: { short_description: { html = '' } = {} } = {} } = this.props;
        const { product: { attributes: { listetekst: { attribute_value: html = '' } = {} } = {} } = {} } = this.props;

        if (html == '') {
            return null;
        }

        return this.renderCardLinkWrapper(
            <div
                block="ProductCard"
                elem="ShortDescription"
                mods={ { isLoaded: !!html } }
            >
                <Html content={ html } />
            </div>
        );
    }

    renderSku() {
        const { product: { sku = '' } = {} } = this.props;

        if (sku == '') {
            return null;
        }

        return this.renderCardLinkWrapper(
            <div
                block="ProductCard"
                elem="Sku"
                mods={ { isLoaded: !!sku } }
            >
                {`${__('Model/SKU:')} `}
                <TextPlaceholder content={ sku } length="medium" />
            </div>
        );
    }

    renderCardContent() {
        const { renderContent } = this.props;

        if (renderContent) {
            return renderContent(this.contentObject);
        }

        return (
            this.renderCardLinkWrapper((
                <>
                    <div block="ProductCard" elem="FigureReview">
                        <figure block="ProductCard" elem="Figure">
                            {this.renderPicture()}
                        </figure>
                        {this.renderBadges()}
                        {this.renderPriceRunnerImage()}
                        {this.renderReviews()}
                    </div>
                    <div block="ProductCard" elem="Content">
                        {/* {this.renderAdditionalProductDetails()} */}
                        {this.renderMainDetails()}
                        {this.renderShortDescription()}
                        {this.renderSku()}
                        {this.renderProductPrice()}
                        {this.renderVisualConfigurableOptions()}
                    </div>
                </>
            ))
        );
    }

    renderCardListContent() {
        const { children, layout, renderContent, hideAddToCartButton } = this.props;

        if (renderContent) {
            return renderContent(this.contentObject);
        }
        
        return (
            <div block="ProductCard" elem="Link">
                {this.renderCardLinkWrapper(
                    <div block="ProductCard" elem="FigureReview">
                        <figure block="ProductCard" elem="Figure">
                            {this.renderPicture()}
                        </figure>
                    </div>
                )}
                <div block="ProductCard" elem="Content" mods={{ layout }}>
                    <div block="ProductCard" elem="MainInfo">
                        {/* {this.renderAdditionalProductDetails()} */}
                        {this.renderMainDetails()}
                        {this.renderReviews()}
                    </div>
                    <div block="ProductCard" elem="AttributeWrapper">
                        {this.renderProductPrice()}
                        {this.renderVisualConfigurableOptions()}
                    </div>
                    <div block="ProductCard" elem="ActionWrapper">
                        {hideAddToCartButton ? <></> : this.renderAddToCart()}
                        {hideAddToCartButton ? <></> : this.renderProductActions()}
                    </div>
                    <div block="ProductCard" elem="AdditionalContent">
                        {children}
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const {
            children,
            mix,
            isLoading,
            siblingsHaveBrands,
            siblingsHavePriceBadge,
            siblingsHaveTierPrice,
            siblingsHaveConfigurableOptions,
            layout,
            hideAddToCartButton
        } = this.props;

        const mods = {
            siblingsHaveBrands,
            siblingsHavePriceBadge,
            siblingsHaveTierPrice,
            siblingsHaveConfigurableOptions
        };

        if (layout === LIST_LAYOUT) {
            return (
                <li
                  block="ProductCard"
                  mods={ mods }
                  mix={ mix }
                >
                    <Loader isLoading={ isLoading } />
                    { this.renderCardListContent() }
                </li>
            );
        }

        return (
            <li
              block="ProductCard"
              mods={ mods }
              mix={ mix }
            >
                <Loader isLoading={ isLoading } />
                { this.renderCardContent() }
                { hideAddToCartButton ? <></> : this.renderAddToCart() }
                { hideAddToCartButton ? <></> : this.renderProductActions() }
                <div block="ProductCard" elem="AdditionalContent">
                    { children }
                </div>
            </li>
        );
    }
}

export default ProductCard;
