/* eslint-disable */
import getStore from 'Util/Store';

const render = (args, callback, instance) => {
    const {
        category: {
            id = ''
        } = {}
    } = instance.props;

    const store = getStore().getState();

    const privateLabelId = store?.ConfigReducer?.private_label_category_category_id;

    if (id == privateLabelId) {
        return null;
    }

    return callback.apply(instance, args);
}

export default {
    'Component/CategoryMenuItem/Component': {
        'member-function': {
            render
        }
    }
}