/* eslint-disable */

import { ProductListQuery as SourceProductListQuery } from 'HoeksQuery/ProductList.query';
import { Field } from 'Util/Query';
export * from 'HoeksQuery/ProductList.query';
/**
 * Product List Query
 * @class ProductListQuery
 * @namespace Query/ProductList
 */
export class ProductListQuery extends SourceProductListQuery {
  _getProductInterfaceFields(isVariant, isForLinkedProducts = false) {
    return [
      ...super._getProductInterfaceFields(isVariant, isForLinkedProducts),
      'leveringstid',
      "con_smagshjul",
      "con_type",
      // "smagsnuancer_syrlighed",
      "con_intensitet_krop",
      "con_sodme",
      // "smagsnuancer_kompleksitet",
      "con_frugtighed",
      "con_eftersmag",
      "con_alkohol",
      this.getBrewingMethodsField()
    ];
  }

  getBrewingMethodsField() {
    return new Field('brewing_methods')
      .addFieldList(['label', 'value']);
  }
}


export default new ProductListQuery();