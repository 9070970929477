const sortShippingMethods = (args, callback, instance) => {
    const [{estimateShippingCosts}] = args;
    
    estimateShippingCosts.sort((a,b) => (a.sort_order-b.sort_order));
    return callback(...args);
}



export default {
    'Route/Checkout/Container/onShippingEstimationFieldsChangeFetchMutationThen': {
        'function': sortShippingMethods
    }
};