import { LINK_TYPE } from '../../component/Clerk/Clerk.config';
import ProductListQuery from 'Query/ProductList.query';
import { getIndexedProduct } from 'Util/Product';

export const getIndexedClerkProduct = (product, currency = 'DKK') => {
    const url = new URL(product.url);
    const minPrice = product.price; 
    const regularPrice = product.list_price; 
    return {
        sku: product.sku.toString(),
        id: product.id,
        name: product.name,
        small_image: {
            url: product.image
        },
        thumbnail: {
            url: product.image
        },
        price_range: {
            minimum_price: {
                discount: {
                    amount_off: regularPrice-minPrice,
                    percent_off: (1-minPrice/regularPrice)*100
                },
                final_price: {
                    currency: currency,
                    value: minPrice
                },
                final_price_excl_tax: {
                    currency: currency,
                    value: minPrice
                },
                regular_price: {
                    currency: currency,
                    value: regularPrice
                },
                regular_price_excl_tax: {
                    currency: currency,
                    value: regularPrice
                }
            }
        },
        url: url['pathname']
    };
}

export const prepareRequest = (skus, pageSize = 20) => {
    return [
        ProductListQuery.getQuery({
            args: {
                filter: {
                    productsSkuArray: skus
                },
                pageSize
            },
            notRequireInfo: true
        })
    ];
} 

export const processItems = (data, skus, indexed = false) => {
    const { products: { items } = {} } = data;
    const indexedItems = indexed ? data : items.map(getIndexedProduct)
    const result = skus.reduce((acc, sku) => {
        const item = indexedItems.find(i => i.sku == sku);
        if (item){
            acc.push(item);
        }
        return acc;
    }, []);
    return result;
}

export const processLinkedData = (data, skus, clerkData = []) => {
    const { products: { items } = {} } = data;
    const result = clerkData.map(item => {
        const dataItem = items.find(i => i.sku == item.sku);
        if (dataItem){
            return getIndexedProduct(dataItem);
        }
        else {
            return item;
        }
    });
    return processLinked(result, skus, true);
}

export const processLinked = (data, skus, indexed = false) => {
    const { products: { items } = {} } = data;
    const indexedItems = indexed ? data : items.map(getIndexedProduct)
    const linkedProducts = skus.reduce((acc, sku) => {
        const item = indexedItems.find(i => i.sku == sku);
        if (item){
            acc[LINK_TYPE].items.push(item);
            acc[LINK_TYPE].total_count++;
        }
        return acc;
    }, {
        [LINK_TYPE]: { total_count: 0, items: [] },
    });
    return linkedProducts;
}
