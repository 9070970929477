/* eslint-disable @scandipwa/scandipwa-guidelines/create-config-files */
/* eslint-disable */

export * from 'SourceUtil/Request/Request';
import { report } from 'Util/Report';

/**
  * Checks for errors in response, if they exist, rejects promise
  * @param  {Object} res Response from GraphQL endpoint
  * @return {Promise<Object>} Handled GraphqlQL results promise
  * @namespace Util/Request/checkForErrors
  */
 export const checkForErrors = (res) => new Promise((resolve, reject) => {
     const { errors, data } = res;
     if (errors) report({errors, data});
     return errors ? reject(errors) : resolve(data);
 });