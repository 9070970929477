/* eslint-disable */

import { CartQuery as SourceCartQuery } from 'SourceQuery/Cart.query';
import { Field } from 'Util/Query';
export * from 'SourceQuery/Cart.query';

export class CartQuery extends SourceCartQuery {
    _getCartTotalsFields() {
        return [
            ...super._getCartTotalsFields(),
            'is_active'
        ];
    }

    getReorderMutation(orderId) {
        const mutation = new Field('reorderItems')
            .addArgument('orderNumber', 'String!', orderId)
            .addFieldList(this._getReorderOutputField());
        return mutation;
    }

    _getReorderOutputField(){
        return [
            this._getReorderCart(),
            this._getReorderErrorMessage()
        ];
    }

    _getReorderCart() {
        return new Field('cart').addFieldList([this.getCartQuery()]);
    }

    _getReorderErrorMessage() {
        return new Field('userInputErrors')
            .addFieldList([
            'message'
        ]);
    }
}

export default new CartQuery();