/* eslint-disable */
import SourceProduct from '@scandipwa/gtm/src/component/GoogleTagManager/utils/Product';
import { NOT_APPLICABLE } from '@scandipwa/gtm/src/component/GoogleTagManager/utils/Product';
export * from '@scandipwa/gtm/src/component/GoogleTagManager/utils/Product';

export const OLD_PRODUCT_ID = 'old_product_id';

export default class Product extends SourceProduct {
    static getAttributeValue(variant, attributeName) {
        const { attribute_value = NOT_APPLICABLE } = (variant.attributes ? variant.attributes[attributeName] : {}) || {};

        return attribute_value || NOT_APPLICABLE;
    }

    /**
     * Get product data as object
     *
     * @param product
     *
     * @param isVariantPassed
     * @return {{quantity: number, price: number, name: string, variant: string, id: string, availability: boolean, list: string, category: string, brand: string}}
     */
    static getProductData(product, isVariantPassed = false) {
        const {
            sku,
            variants = [],
            configurableVariantIndex = this.getSelectedVariantIndex(product, sku)
        } = product;
        const selectedVariant = variants[configurableVariantIndex] || product;
        return {
            ...super.getProductData(product, isVariantPassed),
            [OLD_PRODUCT_ID]: this.getAttributeValue(selectedVariant, 'old_product_id')
        }
    }
}
