import { CategoryMenuItem as SourceCategoryMenuItem } from '@hoeks/category-menu/src/component/CategoryMenuItem/CategoryMenuItem.component';

import './CategoryMenuItem.override.style';

export * from '@hoeks/category-menu/src/component/CategoryMenuItem/CategoryMenuItem.component';

/** @namespace HoeksPwa/Component/CategoryMenuItem/Component */
export class CategoryMenuItem extends SourceCategoryMenuItem {

}

export default CategoryMenuItem;
