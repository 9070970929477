/* eslint-disable */
import { connect } from 'react-redux';
import PriceSlider from './PriceSlider.component';
import { debounce } from 'Util/Request';
import ProductListQuery from 'Query/ProductList.query';
import DataContainer from 'Util/Request/DataContainer';
import { PRICE } from './PriceSlider.config';

/** @namespace Component/PriceSlider/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
});

/** @namespace Component/PriceSlider/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    categoryId: state.ProductListInfoReducer?.selectedFilter?.categoryIds,
    price: state.ProductListInfoReducer?.selectedFilter?.customFilters?.price,
    vatRate: state.ConfigReducer.vatPercentage
});

/** @namespace Component/PriceSlider/Container */
export class PriceSliderContainer extends DataContainer {
    state = {
        minVal: 0,
        maxVal: 0,
        maxPrice: 0
    }

    componentDidMount() {
        const { price: [ priceFilter = '' ] = [] } = this.props;

        const vatRateMultiplier = this.getVatRateMultiplier();

        const priceParts = priceFilter.split('_');
        const low = parseInt(priceParts[0] || 0);
        const high = parseInt(priceParts[1] || 0);

        this.setState(state => ({
            ...state,
            minVal: Math.floor(low * vatRateMultiplier),
            maxVal: Math.ceil(high * vatRateMultiplier)
        }));

        this.updatePriceFilter = debounce(this.updatePriceFilter.bind(this), 1500);
        this.updateMaxPrice = this.updateMaxPrice.bind(this);
        this.getMaxPrice = this.getMaxPrice.bind(this);
        this.loadCategoryInfo();
    }

    componentDidUpdate(prevProps, prevState) {
        const {
            minVal: prevMinVal,
            maxVal: prevMaxVal
        } = prevState;

        const {
            minVal,
            maxVal
        } = this.state;

        if (prevMinVal !== minVal || (prevMaxVal !== maxVal && prevMaxVal !== 0)) {
            this.updatePriceFilter();
        }
    }

    containerFunctions = {
        onMinSliderChange: this.onMinSliderChange.bind(this),
        onMaxSliderChange: this.onMaxSliderChange.bind(this)
    }

    containerProps = () => ({
        isMinTop: this.getIsMinTop(),
        isMaxTop: this.getIsMaxTop()
    })

    getIsMinTop() {
        const { maxPrice, minVal } = this.state;
        const maxPriceFraction = maxPrice / 10;

        return minVal > (maxPrice - maxPriceFraction);
    }

    getIsMaxTop() {
        const { maxPrice, maxVal } = this.state;
        const maxPriceFraction = maxPrice / 10;

        return maxVal < maxPriceFraction;
    }

    loadCategoryInfo() {
        const { categoryId } = this.props;

        if (!categoryId) {
            return;
        }

        const infoOptions = {
            args: {
                filter: {
                    categoryIds: categoryId
                },
                search: ''
            }
        }

        this.fetchData(
            ProductListQuery.getQuery({
                ...infoOptions,
                requireInfo: true
            }),
            this.updateMaxPrice,
            err => console.log(err)
        )
    }

    updateMaxPrice(data) {
        const {
            products: {
                filters = []
            } = {}
        } = data;

        const priceFilter = filters.find(({ request_var }) => request_var === PRICE);

        if (priceFilter) {
            const maxPrice = this.getMaxPrice(priceFilter);
            this.setState(state => ({ ...state, maxPrice, maxVal: state.maxVal !== 0 ? state.maxVal : maxPrice }));
        }
    }

    updatePriceFilter() {
        const {
            minVal,
            maxVal
        } = this.state;

        const {
            toggleCustomFilter
        } = this.props;

        const vatRateMultiplier = this.getVatRateMultiplier();

        toggleCustomFilter(PRICE, `${Math.floor(minVal / vatRateMultiplier)}_${Math.ceil(maxVal / vatRateMultiplier)}`);
    }

    getMaxPrice(priceFilter) {
        const {
            filter_items = []
        } = priceFilter;

        const vatRateMultiplier = this.getVatRateMultiplier();

        if (filter_items) {
            return Math.ceil(Object.entries(filter_items).reduce((acc, [key, { label }]) => {
                const parts = label.split('~');
                const minPart = parseInt(parts[0]);
                const maxPart = parseInt(parts[1] || 0);
                const values = [minPart, maxPart, acc].filter(value => !isNaN(value));
                return Math.max(...values);
            }, 0) * vatRateMultiplier);
        }

        return 0;
    }

    getVatRateMultiplier() {
        const { vatRate } = this.props;

        return 1 + vatRate;
    }

    onMinSliderChange(e) {
        e.preventDefault();
        const { maxVal } = this.state;
        const val = +e.target.value;
        if (val <= maxVal) {
            this.setState(state => ({ ...state, minVal: val }));
        }
    }

    onMaxSliderChange(e) {
        e.preventDefault();
        const { minVal } = this.state;
        const val = +e.target.value;
        if (val >= minVal) {
            this.setState(state => ({ ...state, maxVal: val }));
        }
    }

    render() {
        return (
            <PriceSlider
                { ...this.props }
                { ...this.state }
                { ...this.containerFunctions }
                { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PriceSliderContainer);