/* eslint-disable */
import { getAuthorizationToken } from 'Util/Auth';
import { hash } from 'Util/Request/Hash';
import getStore from 'Util/Store';

const MAX = Number.MAX_SAFE_INTEGER;

const getQuery = (args, callback, instance) => {
    const [{ urlParam }] = args;

    const result = callback.apply(instance, args);

    const token = getAuthorizationToken();
    const store = getStore().getState();

    const url = store?.ConfigReducer?.private_label_category_url;

    if (urlParam == url && token) {
        const hashedToken = hash(token, Math.floor(Math.random * MAX));
        result.addArgument('token', 'String', hashedToken);
    }

    return result;
}

export default {
    'Query/UrlRewrites': {
        'member-function': {
            getQuery,
        }
    }
}