/* eslint-disable */
import ContentWrapper from 'Component/ContentWrapper';
import CheckoutSuccess from 'Component/CheckoutSuccess';
import Loader from 'Component/Loader';
import Link from 'Component/Link';
import {
    EPAY_ACCEPT,
    EPAY_CANCEL
} from './EpayGateway.config';

/** @namespace Route/EpayGateway/Component */
export class EpayGateway extends PureComponent {
    renderMap = {
        [EPAY_ACCEPT]: {
            title: __('Thank you for your purchase!'),
            render: this.renderSuccess.bind(this),
            action: this.actionAccept.bind(this)
        },
        [EPAY_CANCEL]: {
            title: __('Cancelling order'),
            render: this.renderCancel.bind(this),
            action: this.actionCancel.bind(this)
        }
    };

    componentDidMount() {
        const { resolution } = this.props;
        const { action } = this.renderMap[resolution];
        action();
    }

    actionAccept() {
        const { loadOrder } = this.props;
        loadOrder();
    }

    actionCancel() {
        const { cancelOrder } = this.props;
        cancelOrder();
    }

    renderResolution() {
        const { resolution } = this.props;
        const { render } = this.renderMap[resolution];
        return render();
    }

    renderTitle() {
        const { resolution } = this.props;
        const { title = '' } = this.renderMap[resolution];

        return (
            <h1 block="EpayGateway" elem="Title">
                { title }
            </h1>
        );
    }

    renderSuccess() {
        const { orderId, isLoading } = this.props;
        if (isLoading){
            return <Loader isLoading={ isLoading }/>;
        }
        return <CheckoutSuccess orderID={ orderId }/>;
    }

    renderCancel() {
        return (
            <div block="EpayGateway">
                <h3>{ __('Your order has been cancelled') }</h3>
                { this.renderButtons() }
            </div>
        );
    }

    renderButtons() {
        return (
            <div block="EpayGateway" elem="ButtonWrapper">
                <Link
                  block="Button"
                  mix={ { block: 'EpayGateway', elem: 'ContinueButton' } }
                  to="/"
                >
                    { __('Continue shopping') }
                </Link>
            </div>
        );
    }

    render() {
        return (
            <main block='EpayGateway'>
                <ContentWrapper
                    wrapperMix={ { block: 'EpayGateway', elem: 'Wrapper' } }
                    label={ __('Payment page') }
                >
                    { this.renderTitle() }
                    { this.renderResolution() }
                </ContentWrapper>
            </main>
        )
    }
}

export default EpayGateway