/* eslint-disable eqeqeq */
import { connect } from 'react-redux';
import { ProductCardContainer as SourceProductCardContainer, mapStateToProps, mapDispatchToProps } from 'SourceComponent/ProductCard/ProductCard.container';
import { IN_STOCK } from './ProductCard.config';

export class ProductCardContainer extends SourceProductCardContainer {
    containerFunctions = {
        getAttribute: this.getAttribute.bind(this),
        isConfigurableProductOutOfStock: this.isConfigurableProductOutOfStock.bind(this),
        isBundleProductOutOfStock: this.isBundleProductOutOfStock.bind(this),
        productHasSpecialPrice: this.productHasSpecialPrice.bind(this),
        // productIsInStock: this.productIsInStock.bind(this)
        productIsOkologisk: this.productIsOkologisk.bind(this)
    };

    containerProps = () => ({
        availableVisualOptions: this._getAvailableVisualOptions(),
        currentVariantIndex: this._getCurrentVariantIndex(),
        productOrVariant: this._getProductOrVariant(),
        thumbnail: this._getThumbnail(),
        linkTo: this._getLinkTo(),
        productIsInStock: this.productIsInStock()
    });

    _getThumbnail() {
        const product = this._getProductOrVariant();
        const { small_image: { url, path } = {} } = product;
        if (this._isThumbnailAvailable(path)) {
            return url;
        }

        const { product: { small_image: { url: parentUrl} = {} } } = this.props;
        if (this._isThumbnailAvailable(parentUrl)) {
            return parentUrl;
        }

        return '';
    }

    productIsOkologisk() {
        const { product: { attributes: { con_okologisk: { attribute_options: okologisk_options } = {} } = {} } = {} } = this.props;

        if (okologisk_options == null || okologisk_options == undefined) {
            return false;
        }

        // if con_okologisk label is 'Ja'/'Yes' return true
        return Object.entries(okologisk_options)[0][1].label == 'Ja'
            || Object.entries(okologisk_options)[0][1].label == 'Yes';
    }

    productIsInStock() {
        const { product: { stock_status } } = this.props;
        if (stock_status !== IN_STOCK) {
            return false;
        }

        return true;
    }

    productHasSpecialPrice() {
        const { product: { price_range, special_from_date, special_to_date } } = this.props;
        if (price_range != undefined) {
            const {
                maximum_price,
                minimum_price
            } = price_range;

            if (maximum_price != undefined) {
                const {
                    discount: {
                        amount_off,
                        percent_off
                    }
                } = maximum_price;

                if (amount_off || percent_off) {
                    return this.checkForSpecialPrice(special_from_date, special_to_date);
                }
            }

            if (maximum_price == undefined && minimum_price != undefined) {
                const {
                    discount: {
                        amount_off,
                        percent_off
                    }
                } = minimum_price;

                if (amount_off || percent_off) {
                    return this.checkForSpecialPrice(special_from_date, special_to_date);
                }
            }
        }

        return false;
    }

    checkForSpecialPrice(special_from_date, special_to_date) {
        const today = new Date();

        if (this.parseDate(special_from_date) <= today && this.parseDate(special_to_date) >= today) {
            return true;
        }

        if (this.parseDate(special_from_date) <= today && special_to_date == null) {
            return true;
        }

        if (special_from_date == null && this.parseDate(special_to_date) >= today) {
            return true;
        }

        if (special_from_date == null && special_to_date == null) {
            return true;
        }

        if (special_from_date == undefined && special_to_date == undefined) {
            return true;
        }

        return false;
    }

    parseDate(date) {
        if (date == null || date == undefined || date == '') {
            return null;
        }
        const dateArr = date.split(/[^0-9]/);
        const parsedDate = new Date(dateArr[0], dateArr[1] - 1, dateArr[2], dateArr[3], dateArr[4], dateArr[5]);
        return parsedDate;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductCardContainer);
