import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { executeGet } from 'Util/Request';
import { prepareQuery } from 'Util/Query';
import { withRouter } from 'react-router-dom';

import ProductList from 'Component/ProductList';
import { updateLoadStatus, appendPage, updateProductListItems } from 'Store/ProductList/ProductList.action';
import { CLERK_KEY } from '../Clerk/Clerk.config';
import { FilterInputType } from 'Type/ProductList';
import { getIndexedClerkProduct, prepareRequest, processItems } from '../../util/Clerk';
import { callApi } from '../../util/Clerk/Api';

export const mapStateToProps = (state) => ({
    apikey: state.ConfigReducer[CLERK_KEY],
    pages: state.ProductListReducer.pages,
    isOffline: state.OfflineReducer.isOffline,
    isLoading: state.ProductListReducer.isLoading,
    isPageLoading: state.ProductListReducer.isPageLoading,
    totalItems: state.ProductListReducer.totalItems,
    totalPages: state.ProductListReducer.totalPages,
    defaultCurrency: state.ConfigReducer.default_display_currency_code,
    searchTemplate: state.ConfigReducer.clerk_search_template
});

export const mapDispatchToProps = (dispatch) => ({
    appendPage: (items, currentPage) => dispatch(appendPage(items, currentPage)),
    updateProductListItems: (minPage, maxPage, totalPages, loadedPagesCount) =>
        dispatch(updateProductListItems(minPage, maxPage, totalPages, loadedPagesCount)),
    updateLoadStatus: (isLoading) => dispatch(updateLoadStatus(isLoading))
});

/** @namespace Hoeks/Clerk/Component/ClerkProductList/Container */
export class ClerkProductListContainer extends PureComponent {
    static propTypes = {
        isLoading: PropTypes.bool.isRequired,
        isMatchingListFilter: PropTypes.bool,
        isMatchingInfoFilter: PropTypes.bool,
        layout: PropTypes.string,
        filter: FilterInputType,
        isCurrentCategoryLoaded: PropTypes.bool
    };

    static defaultProps = {
        isMatchingListFilter: false,
        isMatchingInfoFilter: false,
        isCurrentCategoryLoaded: false,
        filter: {},
        layout: 'grid'
    };

    state = {
        pages: [],
        totalItems: 0,
        totalPages: 0,
        isLoading: true,
        isPageLoading: true,
        ranInit: false
    }

    containerFunctions = {
        requestProductList: this.requestProductList.bind(this)
    };

    getIsLoading() {
        const {
            filter,
            isLoading,
            isMatchingListFilter,
            isCurrentCategoryLoaded
        } = this.props;

        /**
         * In case the wrong category was passed down to the product list,
         * show the loading animation, it will soon change to proper category.
         */
        if (filter.categoryIds === -1) {
            return true;
        }

        /**
         * Do not request page, if category is not yet loaded
         * without this command the products are requested twice:
         * 1. Once with global default sorting
         * 2. Once with category default sortingZ
         */
        if (!isCurrentCategoryLoaded) {
            return true;
        }

        if (!navigator.onLine) {
            return false;
        }

        return isLoading;
    }

    getIsPreventRequest() {
        const { isMatchingListFilter, isMatchingInfoFilter } = this.props;

        return isMatchingListFilter && isMatchingInfoFilter; // if filter match - prevent request
    }

    getLayout() {
        const { layout } = this.props;

        return layout;
    }
    increment = 0;

    componentDidMount() {
        const { apikey } = this.props;
        if (apikey != undefined) {
            this.init();
            this.setState({ranInit: true});
        }
    }

    componentDidUpdate() {
        const { apikey } = this.props;
        const { ranInit } = this.state;
        if (!ranInit && apikey != undefined) {
            this.init();
            this.setState({ranInit: true});
        }
    }

    init() {
        const {
            search,
            history: {
                location: {
                    search: queryParams = ''
                } = {}
            } = {},
            pages
        } = this.props;

        const params = new URLSearchParams(queryParams);
        const currentPage = !params.has('page') || isNaN(params.get('page')) ? 1 : +params.get('page');

        const options = { args: { currentPage: currentPage, pageSize: 24, search: search, isNext: false } };
        if (search !== window.lastSearch || pages.length === 0) {
            this.requestProductList(options);
        }
    }

    requestProductList(options) {
        const { args, args: { currentPage, pageSize, search }, isNext } = options;
        const { apikey, searchTemplate } = this.props;
        window.lastSearch = search;
        this.increment++;
        const payload = {
            limit: pageSize,
            attributes: ['id', 'name', 'price', 'image', 'url', 'sku', 'list_price', 'on_sale'],
            query: search,
            offset: (currentPage - 1) * pageSize,
        };
        if (searchTemplate){
            payload['template'] = searchTemplate;
        }
        const call = searchTemplate ? '' : 'search/search';
        callApi(
            call,
            payload,
            (response) => this.handleResponse(response, args, isNext),
            function (response) {
                console.error(response);
            },
            apikey
        );

    }

    handleResponse = (response, args, isNext) => {
        const { updateProductListItems, appendPage, defaultCurrency } = this.props;
        const { currentPage, pageSize } = args;

        const { product_data, hits: count } = response;
        const skus = product_data.map(item => item.sku);
        const indexedItems = response.product_data.map((item) => getIndexedClerkProduct(item, defaultCurrency));
        if (isNext) {
            console.log('debug - append', currentPage, args);
            appendPage(
                processItems(indexedItems, skus, true),
                currentPage
            );
        }
        else {
            updateProductListItems(
                processItems(indexedItems, skus, true),
                currentPage,
                count,
                Math.ceil(count / pageSize),
                args
            );
        }
        const incremet = this.increment;
        executeGet(prepareQuery(prepareRequest(skus, skus.length)), 'ClerkProducts', 86400)
            .then((result) => this.onSuccess(result, skus, currentPage, indexedItems, incremet))
            .catch(
                (e) => console.log('error', 'Error fetching NewProducts!', e)
            );
    }

    onSuccess(data, skus, currentPage, indexedItems, increment) {
        const { appendPage } = this.props;
        const { products: { items } } = data;
        if (this.increment !== increment) {
            return;
        }

        const sortedItems = skus.reduce((acc, sku) => {
            const item = items.find(i => i.sku == sku) ?? indexedItems.find(i => i.sku == sku);
            if (item) {
                acc.push(item);
            }
            return acc;
        }, []);
        appendPage(
            sortedItems,
            currentPage
        );

    }

    containerProps = () => ({
        isLoading: this.getIsLoading(),
        isPreventRequest: this.getIsPreventRequest(),
        mix: { block: 'CategoryProductList', mods: { layout: this.getLayout() } }
    });

    render() {
        return (
            <ProductList
                {...this.props}
                {...this.containerFunctions}
                {...this.containerProps()}
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ClerkProductListContainer));
