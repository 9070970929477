/* eslint-disable */
import { connect } from 'react-redux';
import EpayGateway from './EpayGateway.component';
import { fetchMutation } from 'Util/Request';
import EpayQuery from '../../query/Epay.query';
import { toggleBreadcrumbs } from 'Store/Breadcrumbs/Breadcrumbs.action';
import { EPAY_PAYMENT_TOTALS, EPAY_PAYMENT_ITEMS } from '../../component/Epay/Epay.config';
import BrowserDatabase from 'Util/BrowserDatabase';

export const BreadcrumbsDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Breadcrumbs/Breadcrumbs.dispatcher'
);

/** @namespace Route/EpayGateway/Container/mapStateToProps */
export const mapStateToProps = (state) => ({});

/** @namespace Route/EpayGateway/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    toggleBreadcrumbs: (isActive) => dispatch(toggleBreadcrumbs(isActive))
})

/** @namespace Route/EpayGateway/Container */
export class EpayGatewayContainer extends PureComponent {
    state = {
        isLoading: true,
        orderId: null
    }

    componentDidMount() {
        const { toggleBreadcrumbs, history } = this.props;
        toggleBreadcrumbs(false);

        const resolution = this.getResolution();

        if (!resolution) {
            history.push('/notfound');
        }
    }

    async loadOrderData() {
        const {
            location: {
                search
            } = {},
            history
        } = this.props;

        const searchParams = new URLSearchParams(search);

        if (searchParams.has('orderid')) {
            const orderId = searchParams.get('orderid');

            const { epayAccept } = await fetchMutation(EpayQuery.getEpayAccept(orderId));
            if (epayAccept) {
                const totals = BrowserDatabase.getItem(EPAY_PAYMENT_TOTALS);
                const items = BrowserDatabase.getItem(EPAY_PAYMENT_ITEMS);
                BrowserDatabase.deleteItem(EPAY_PAYMENT_TOTALS);
                BrowserDatabase.deleteItem(EPAY_PAYMENT_ITEMS);

                this.setState({ isLoading: false, orderId });
                return {
                    totals,
                    items,
                    orderId
                }
            } else {
                return false;
            }
        } else {
            history.push('/notfound');
            return false;
        }
    }

    async cancelOrderData() {
        await fetchMutation(EpayQuery.getEpayCancel());
    }

    getResolution() {
        const {
            match: {
                params: {
                    resolution
                } = {}
            } = {}
        } = this.props;

        return resolution;
    }

    containerProps = () => {
        return {
            resolution: this.getResolution()
        }
    }

    containerFunctions = {
        loadOrder: this.loadOrderData.bind(this),
        cancelOrder: this.cancelOrderData.bind(this)
    }

    render() {
        return (
            <EpayGateway
                { ...this.containerProps() }
                { ...this.containerFunctions }
                { ...this.state }
            />
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EpayGatewayContainer);