/* eslint-disable */

import { HeaderContainer as SourceHeaderContainer, mapStateToProps as sourceMapStateToProps } from 'SourceComponent/Header/Header.container';
import { connect } from 'react-redux';
import { isSignedIn } from 'Util/Auth';
import history from 'Util/History';
import { fetchQuery, fetchMutation } from 'Util/Request';
import CartQuery from 'Query/Cart.query';
import { getGuestQuoteId, setGuestQuoteId } from 'Util/Cart';
import { showNotification } from 'Store/Notification/Notification.action';

import { CUSTOMER_ACCOUNT_OVERLAY_KEY } from 'Component/MyAccountOverlay/MyAccountOverlay.config';
import { DEFAULT_STATE_NAME } from 'Component/NavigationAbstract/NavigationAbstract.config';
import { NavigationAbstractContainer } from 'Component/NavigationAbstract/NavigationAbstract.container';
import { SHARE_WISHLIST_POPUP_ID } from 'Component/ShareWishlistPopup/ShareWishlistPopup.config';
import { CHECKOUT_URL } from 'Route/Checkout/Checkout.config';
import { changeNavigationState, goToPreviousNavigationState } from 'Store/Navigation/Navigation.action';
import { TOP_NAVIGATION_TYPE } from 'Store/Navigation/Navigation.reducer';
import { hideActiveOverlay, toggleOverlayByKey } from 'Store/Overlay/Overlay.action';
import { showPopup } from 'Store/Popup/Popup.action';
import { DeviceType } from 'Type/Device';
import { appendWithStoreCode, setQueryParams } from 'Util/Url';

import Header from './Header.component';
import {
    CART,
    CART_OVERLAY, CATEGORY,
    CHECKOUT, CHECKOUT_ACCOUNT, CHECKOUT_SUCCESS,
    CMS_PAGE, CONTACT_US, CUSTOMER_ACCOUNT,
    CUSTOMER_ACCOUNT_PAGE, CUSTOMER_SUB_ACCOUNT,
    MENU, PDP,
    SEARCH
} from './Header.config';

export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    breadcrumbs: state.BreadcrumbsReducer?.breadcrumbs,
    isSignedIn: state.MyAccountReducer?.isSignedIn,
    code: state.ConfigReducer?.code
});

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);

export const mapDispatchToProps = (dispatch) => ({
    showOverlay: (overlayKey) => dispatch(toggleOverlayByKey(overlayKey)),
    hideActiveOverlay: () => dispatch(hideActiveOverlay()),
    setNavigationState: (stateName) => dispatch(changeNavigationState(TOP_NAVIGATION_TYPE, stateName)),
    showPopup: (payload) => dispatch(showPopup(SHARE_WISHLIST_POPUP_ID, payload)),
    goToPreviousNavigationState: () => dispatch(goToPreviousNavigationState(TOP_NAVIGATION_TYPE)),
    showNotification: (type, message) => dispatch(showNotification(type, message)),
    updateCartData: (cartData) => CartDispatcher.then(
        ({default: dispatcher}) => dispatcher._updateCartData(cartData, dispatch)
    )
})

export class HeaderContainer extends SourceHeaderContainer {
    __construct(props) {
        super.__construct(props);

        this.state = {
            prevPathname: '',
            searchCriteria: '',
            isClearEnabled: this.getIsClearEnabled(),
            showMyAccountLogin: false,
            topMenuIsClosed: false
        };
    }

    componentDidMount() {
        this.handleHeaderVisibility();
        super.componentDidMount();
    }

    componentDidUpdate(prevProps) {
        const {
            breadcrumbs,
            code
        } = this.props;

        const { 
            topMenuIsClosed = false
        } = this.state;

        this.hideSearchOnStateChange(prevProps);
        this.handleHeaderVisibility();

        if (topMenuIsClosed) {
            const headerWrapper = document.getElementsByClassName('Header-Wrapper')[0];
            const hiddenTopMenuClass = 'is-topmenu-hidden';
            if (headerWrapper.classList.value.includes(hiddenTopMenuClass) == false && document.body.classList.value.includes(hiddenTopMenuClass) == false) {
                headerWrapper.classList.add(hiddenTopMenuClass);
                document.body.classList.add(hiddenTopMenuClass);
            }
        }

        if (code == 'berrybean_en_b2b') {
            var isChangePasswordPage = false;
            const { pathname } = history.location;
            const allowedPages = ['/customer/account/createPassword/', '/account/login', '/account/forgotpassword'];
            const isAllowedPage = allowedPages.includes(pathname);
            breadcrumbs.forEach(breadcrumb => {
                if (breadcrumb.name == 'Change password') {
                    isChangePasswordPage = true;
                }
            });

            if (!isSignedIn() && !isAllowedPage && isChangePasswordPage == false) {
                history.push(`/account/login`);
            }
        }
    }

    containerFunctions = {
        ...this.containerFunctions,
        closeTopMenu: this.closeTopMenu.bind(this)
    }

    closeTopMenu() {
        document.getElementsByClassName('Header-TopMenu')[0].style = 'display: none';
        this.setState({topMenuIsClosed: true});
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderContainer);