import { CategoryPaginationLink as SourceCategoryPaginationLink } from 'SourceComponent/CategoryPaginationLink/CategoryPaginationLink.component';

import './CategoryPaginationLink.override.style';

export * from 'SourceComponent/CategoryPaginationLink/CategoryPaginationLink.component';

/** @namespace HoeksPwa/Component/CategoryPaginationLink/Component */
export class CategoryPaginationLink extends SourceCategoryPaginationLink {

}

export default CategoryPaginationLink;
