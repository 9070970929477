/* eslint-disable */
// import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import Link from 'Component/Link';
import Loader from 'Component/Loader';

import './SubscriptionDetails.style';

/** @namespace Hoeks/Subscription/Component/SubscriptionDetails/Component/SubscriptionDetailsComponent */
export class SubscriptionDetailsComponent extends PureComponent {
    static propTypes = {
        // TODO: implement prop-types
    };

    renderNoSubscription() {
        return (
            <div>
                <p>{__('The subscirption with this id does not exist on your account')}</p>
            </div>
        );
    }

    renderLoadingSubscription() {
        return (
            <div>
                <p>{__('Loading subscription')}</p>
            </div>
        );
    }

    renderContent() {
        const { subscription, isLoading } = this.props;
        if (isLoading) return this.renderLoadingSubscription();
        if (subscription == undefined || subscription.subscription_id == null) return this.renderNoSubscription();

        return (
            <>
                {this.renderSubscriptionDetailHeaderLinks()}
                {this.renderSubscriptionProfile()}
                {this.renderSubscriptionPaymentDetails()}
                {this.renderSubscriptionHistory()}
                {this.renderOrderInformation()}
            </>
        );
    }

    renderGoBackLink() {
        const { onBackClicked } = this.props;
        return (
            <div block="SubscriptionDetails" elem="Back">
                <Link
                    to={'/my-account/my-subscriptions/'}
                    onClick={onBackClicked}>
                    X
                </Link>
            </div>
        );
    }

    renderSubscriptionDetailHeaderLinks() {
        const { subscription, onCancelClicked, onEditClicked } = this.props;
        if (subscription == undefined) return;
        const { subscription_status } = subscription;

        return (
            <div>
                {this.renderGoBackLink()}
                {subscription_status != 3 && subscription_status != 4 && subscription_status != 5 ?
                    <>
                        <Link
                            className="Button Button_isHollow"
                            to={`/my-account/my-subscriptions/view?id=${subscription.subscription_id}`}
                            onClick={onCancelClicked}>
                            {__('CANCEL')}
                        </Link>
                        <Link
                            className="Button Button_isHollow"
                            to={`/my-account/my-subscriptions/edit?id=${subscription.subscription_id}`}
                            onClick={() => onEditClicked(subscription.subscription_id)}>
                            {__('EDIT')}
                        </Link>
                    </> : <></>}
            </div>
        )
    }

    renderSubscriptionStatus(subscriptionStatus) {
        switch (subscriptionStatus) {
            case 1:
                return __('Active');

            case 2:
                return __('Pause');

            case 3:
                return __('Expired');

            case 4:
                return __('Cancelled');

            case 5:
                return __('Suspended');

            case 6:
                return __('Failed');

            case 7:
                return __('Completed');

            case 8:
                return __('Renewed');

            default:
                return __('Pending');
        }
    }

    renderSubscriptionProfile() {
        const { subscription } = this.props;
        if (subscription == undefined) return;

        return (
            <div block="SubscriptionDetails" elem="SubscriptionProfile">
                <h3>{__('Subscription Profile')}</h3>
                <table>
                    <tbody>
                        <tr>
                            <th>{__('Profile Number')}</th>
                            <td>{subscription.profile_id}</td>
                        </tr>
                        <tr>
                            <th>{__('Profile Status')}</th>
                            <td>{this.renderSubscriptionStatus(subscription.subscription_status)}</td>
                        </tr>
                        <tr>
                            <th>{__('Product Name')}</th>
                            <td>{subscription.product_name}</td>
                        </tr>
                        <tr>
                            <th>{__('Quantity')}</th>
                            <td>{subscription.qty_subscribed}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }

    renderSubscriptionPaymentDetails() {
        const { subscription, getPrice } = this.props;
        if (subscription == undefined) return;

        return (
            <div block="SubscriptionDetails" elem="SubscriptionPaymentDetails">
                <h3>{__('Subscription Payment Details')}</h3>
                <table>
                    <tbody>
                        <tr>
                            <th>{__('Billing Price')}</th>
                            <td>{getPrice()}</td>
                        </tr>
                        <tr>
                            <th>{__('Payment Method')}</th>
                            <td>{subscription.payment_title}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }

    renderSubscriptionHistory() {
        const { subscription } = this.props;
        if (subscription == undefined) return;

        return (
            <div block="SubscriptionDetails" elem="SubscriptionHistory">
                <h3>{__('Subscription History')}</h3>
                <table>
                    <tbody>
                        <tr>
                            <th>{__('Subscription Generated On')}</th>
                            <td>{subscription.created_at}</td>
                        </tr>
                        <tr>
                            <th>{__('Next Occurrence Date')}</th>
                            <td>{subscription.subscription_status == 4 ? __('-') : subscription.next_occurrence_date}</td>
                        </tr>
                        <tr>
                            <th>{__('Billing Period')}</th>
                            <td>{subscription.billing_period_label}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }

    renderOrderInformation() {
        const { subscription } = this.props;
        if (subscription == undefined) return;

        return (
            <div block="SubscriptionDetails" elem="OrderInfomation">
                <h3>{__('Order information')}</h3>
                <div>
                    <strong>
                        <span>{__('Billing Address')} #{subscription.billing_address_id}</span>
                    </strong>
                </div>
                <div dangerouslySetInnerHTML={{ __html: subscription.formated_billing_address }}></div>
                <br />
                <div>
                    <strong>
                        <span>{__('Shipping Address')} #{subscription.shipping_address_id}</span>
                    </strong>
                </div>
                <div dangerouslySetInnerHTML={{ __html: subscription.formated_shipping_address }}></div>
            </div>
        );
    }

    render() {
        const { isLoading } = this.props;
        return (
            <div block="SubscriptionDetails">
                <Loader isLoading={isLoading} />
                {this.renderContent()}
            </div>
        );
    }
}

export default SubscriptionDetailsComponent;
