import CommentField from './CommentField.component';

export class CommentFieldContainer extends PureComponent {
    state = {
        accepted: false
    };

    containerFunctions = {
        onAccept: this.onAccept.bind(this)
    };

    onAccept(value, checked) {
        const { onChange } = this.props;
        console.log(value);
        this.setState({accepted: checked});
        if (!checked){
            onChange('');
        }
    }

    render(){
        return <CommentField {...this.props} {...this.state} {...this.containerFunctions} />
    }
}

export default CommentFieldContainer;