/* eslint-disable */
import ConfigQuery from 'Query/Config.query';

const prepareRequest = (args, callback, instance) => {
    return [
        ...callback.apply(instance, args),
        ConfigQuery.getVatPercentageQuery()
    ];
}

export default {
    'Store/Config/Dispatcher': {
        'member-function': {
            prepareRequest
        }
    }
}