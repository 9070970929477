/* eslint-disable */
import Html from 'Component/Html';

export const renderCarrierAndMethod = (args, callback, instance) => {
    const {
        option: {
            description = ''
        } = {}
    } = instance.props;

    if (!description) {
        return callback.apply(instance, args);
    }

    return (
        <>
            { callback.apply(instance, args) }
            <div block="CheckoutDeliveryOption" elem="DeliveryDescription">
                <Html content={ description }/>
            </div>
        </>
    )
}

export default {
    'Component/CheckoutDeliveryOption/Component': {
        'member-function': {
            renderCarrierAndMethod
        }
    }
}