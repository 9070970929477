/* eslint-disable */
import { Field } from 'Util/Query';
const getStoreConfigField = (args, callback, instance) => {
    return [
        ...callback(...args),
        'store_id',
        getAllowedShippingCountries()
    ]
}

const getAllowedShippingCountries = () => {
    return new Field('allowed_shipping_countries')
        .addFieldList([
            'id',
            'is_state_required',
            'available_regions',
            'label'
        ]);
}

export default {
    'Query/Config': {
        'member-function': {
            '_getStoreConfigFields': getStoreConfigField
        }
    }
}