/* eslint-disable */

const updateConfig = (args, callback, instance) => {
    const result = callback.apply(instance, args);

    const {
        config: {
            vatPercentage,
            ...reaminingConfig
        },
        ...remainingResult
    } = result;

    const updatedResult = {
        ...remainingResult,
        config: {
            ...reaminingConfig,
            storeConfig: {
                ...reaminingConfig.storeConfig,
                vatPercentage
            }
        }
    };

    return updatedResult;
}

export default {
    'Store/Config/Action/updateConfig': {
        'function': updateConfig
    }
}