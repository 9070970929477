/* eslint-disable */

const attributeOptionSort = ({ label: a }, { label: b }) => {
    const startsWithNumberRegEx = /^\d+/;

    const matchA = a.match(startsWithNumberRegEx);
    const matchB = b.match(startsWithNumberRegEx);

    if (matchA && !matchB) {
        return -1;
    }

    if (!matchA && matchB) {
        return 1;
    }

    if (matchA && matchB) {
        const numA = Number(matchA[0]);
        const numB = Number(matchB[0]);

        if (isNaN(numA) && !isNaN(numB)) {
            return 1;
        }

        if (!isNaN(numA) && isNaN(numB)) {
            return -1;
        }

        if (isNaN(numA) && isNaN(numB)) {
            return a.localeCompare(b);
        }

        return numA - numB;
    }

    return a.localeCompare(b);
}

const _getSelectOptions = (args, callback, instance) => {
    const result = callback.apply(instance, args);

    if (!Array.isArray(result)) {
        return result;
    }

    return result.sort(attributeOptionSort);
}

export default {
    'Component/ProductConfigurableAttributeDropdown/Container': {
        'member-function': {
            _getSelectOptions
        }
    }
}