/* eslint-disable */

import { FieldContainer as SourceFieldContainer } from 'SourceComponent/Field/Field.container';

import validationConfig from 'Component/Form/Form.config';

import Field from './Field.component';
import {
    CHECKBOX_TYPE,
    ENTER_KEY_CODE,
    FILE_TYPE,
    NUMBER_TYPE,
    PASSWORD_TYPE,
    RADIO_TYPE,
    SELECT_TYPE,
    TEXT_TYPE,
    TEXTAREA_TYPE
} from './Field.config';

export class FieldContainer extends SourceFieldContainer {
    static defaultProps = {
        min: 1,
        // max: 99,
        checked: false,
        value: null,
        onChange: () => {},
        onFocus: () => {},
        onBlur: () => {},
        onClick: () => {},
        onKeyPress: () => {},
        formRef: () => {},
        isControlled: false,
        validation: [],
        message: '',
        customValidationStatus: null,
        id: '',
        formRefMap: {}
    };

    containerFunctions = {
        onChange: this.onChange.bind(this),
        handleChange: this.handleChange.bind(this),
        onChangeCheckbox: this.onChangeCheckbox.bind(this),
        onFocus: this.onFocus.bind(this),
        onKeyPress: this.onKeyPress.bind(this),
        onKeyEnterDown: this.onKeyEnterDown.bind(this),
        onClick: this.onClick.bind(this),
        onNumberBlur: this.onBlur.bind(this)
    };

    onBlur() {
        const {defaultValue = ""} = this.props;
        const {value} = this.state;
        console.log('test');
        if (defaultValue !== "" && value === "") {
            this.setState({value: 1});
        }
    }

    handleChange(value, shouldUpdate = true, fileValue = false) {
        const {
            isControlled,
            onChange,
            type,
            min,
            max,
            isCart = false
        } = this.props;

        switch (type) {
        case NUMBER_TYPE:
            const isValueNaN = Number.isNaN(parseInt(value, 10));
            const isValueEmpty = value === "";
            console.log('debug', isValueNaN, isValueEmpty, isCart)
            if ((min > value && !isValueEmpty) || value > max || (isValueNaN && !isValueEmpty)) {
                console.log('debug no value')
                break;
            }
            if ((min > value && isCart) || (isValueNaN && isCart)) {
                break;
            }
            if (onChange && shouldUpdate) {
                onChange(value);
            }
            if (!isControlled) {
                this.setState({ value });
            }
            break;
        case FILE_TYPE:
            if (value) {
                const result = onChange && onChange(fileValue);

                this.setState({
                    value: result ? value : '',
                    filename: result ? value.substr(value.lastIndexOf('\\') + 1) : ''
                });
            }

            break;
        default:
            if (onChange) {
                onChange(value);
            }
            if (!isControlled) {
                this.setState({ value });
            }
        }
    }
}

export default FieldContainer;