/* eslint-disable */
import { Router as SourceRouter } from 'SourceComponent/Router/Router.component';

import Breadcrumbs from 'Component/Breadcrumbs';
import CookiePopup from 'Component/CookiePopup';
import DemoNotice from 'Component/DemoNotice';
import Footer from 'Component/Footer';
import Header from 'Component/Header';
import Loader from 'Component/Loader';
import Meta from 'Component/Meta';
import NavigationTabs from 'Component/NavigationTabs';
import NewVersionPopup from 'Component/NewVersionPopup';
import NotificationList from 'Component/NotificationList';
import OfflineNotice from 'Component/OfflineNotice';
import SomethingWentWrong from 'Route/SomethingWentWrong';
import UrlRewrites from 'Route/UrlRewrites';
import history from 'Util/History';

import {
    AFTER_ITEMS_TYPE,
    BEFORE_ITEMS_TYPE,
    SWITCH_ITEMS_TYPE
} from 'Component/Router/Router.config';

// export * from 'SourceComponent/Router/Router.component';

/** @namespace Component/Router/Component/withStoreRegex */
export const withStoreRegex = (path) => window.storeRegexText.concat(path);

/** @namespace Component/Router/Component */
export class Router extends SourceRouter {
    // [BEFORE_ITEMS_TYPE] = [
    //     {
    //         component: <NotificationList />,
    //         position: 10
    //     },
    //     {
    //         component: <DemoNotice />,
    //         position: 15
    //     },
    //     {
    //         component: <Header />,
    //         position: 5
    //     },
    //     {
    //         component: <NavigationTabs />,
    //         position: 25
    //     },
    //     {
    //         component: <Breadcrumbs />,
    //         position: 30
    //     },
    //     {
    //         component: <NewVersionPopup />,
    //         position: 35
    //     }
    // ];
}

export default Router;