import { ParcelshopPicker as HoeksParcelshopPicker } from '@hoeks/parcelshop/src/component/ParcelshopPicker/ParcelshopPicker.component';

import Loader from 'Component/Loader';
import { SHIPPING_STEP } from 'Route/Checkout/Checkout.config';
import FormPortal from 'Component/FormPortal';

import './ParcelshopPicker.override2.style.scss';

export class ParcelshopPicker extends HoeksParcelshopPicker {
    renderInfoText() {
        return (
            <div block="ParcelshopPicker" elem="InfoText">{__('If you wish the shipment to be delivered to an alternative zip code, simply enter the zip code into the zip code field')}</div>
        );
    }

    render() {
        const { active, isLoading } = this.props;
        if (!active) {
            return null;
        }

        return (
            <div block="ParcelshopPicker">
                <Loader isLoading={isLoading} />
                <h2 block="ParcelshopPicker" elem="Heading">{__('Select pickup location')}</h2>
                {this.renderInfoText()}
                <div block="ParcelshopPicker" elem="Inputs">
                    <FormPortal
                        id={SHIPPING_STEP}
                        name="ParcelshopPicker"
                    >
                        {this.renderZipInput()}
                        {this.renderOptions()}
                    </FormPortal>
                </div>
            </div>
        );
    }
}

export default ParcelshopPicker;
