import './CommentField.override.style.scss';
import Field from 'Component/Field';

export class CommentField extends PureComponent {
    renderAccept(){
        const { onAccept, accepted } = this.props;
        return (
            <Field
                type="checkbox"
                label={__('May be placed without receipt, this happens on the customers own responsibility.')}
                id="shipping_flex_accept"
                name="shipping_flex_accept"
                onChange={ onAccept }
                checked={ accepted }
              />
        );
    }

    renderField(){
        const { onChange, value, accepted } = this.props;

        if( !accepted ) {
            return null;
        }

        return (
            <Field
                type="text"
                label={ __('Please write below were the carrier may leave the packet.') }
                id="shipping_comment"
                name="shipping_comment"
                isDisabled={ !accepted }
                validation={[]}
                onChange={ onChange }
                value={value}
              />
        );
    }

    render(){
        const { active, isLoading } = this.props;
        if (!active){
            return null;
        }

        return (
            <div block="CommentField">
                {this.renderAccept()}
                {this.renderField()}
            </div>
        );
    }
}

export default CommentField;