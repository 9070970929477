/* eslint-disable */
import { Field } from 'Util/Query';

const getVatPercentageQuery = (args, callback, instance) => {
    return new Field('vatPercentage');
}

export default {
    'Query/Config': {
        'member-function': {
            getVatPercentageQuery
        }
    }
}