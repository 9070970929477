// import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import Field from 'Component/Field';
import Link from 'Component/Link';
import { NUMBER_TYPE, SELECT_TYPE } from 'Component/Field/Field.config';
import './SubscriptionEdit.style';

/** @namespace Hoeks/Subscription/Component/SubscriptionEdit/Component/SubscriptionEditComponent */
export class SubscriptionEditComponent extends PureComponent {
    static propTypes = {
        // TODO: implement prop-types
    };

    renderNoSubscription() {
        return (
            <div>
                <p>{__('The subscirption with this id does not exist on your account')}</p>
            </div>
        );
    }

    renderGoBackLink() {
        const { onBackClicked, subscription } = this.props;
        return (
            <div block="SubscriptionDetails" elem="Back">
                <Link
                    to={`/my-account/my-subscriptions/view?id=${subscription.subscription_id}`}
                    onClick={onBackClicked}>
                        X
                </Link>
            </div>
        );
    }

    renderContent() {
        const { subscription } = this.props;
        if (subscription == undefined || subscription.subscription_id == null) return this.renderNoSubscription();

        return (
            <>
                {this.renderUpdateButton()}
                {this.renderGoBackLink()}
                {this.renderSubscriptionProfile()}
                {this.renderSubscriptionPaymentDetails()}
                {this.renderSubscriptionHistory()}
                {this.renderOrderInformation()}
                {this.renderUpdateButton()}
            </>
        )
    }

    renderUpdateButton() {
        const { subscription, onUpdateClicked } = this.props;
        if (subscription == undefined) return;

        return (
            <Link
                className="Button Button_isHollow"
                to={`/my-account/my-subscriptions/view?id=${subscription.subscription_id}`}
                onClick={onUpdateClicked}>
                {__('Update Profile')}
            </Link>
        );
    }

    renderSubscriptionProfile() {
        const { subscription, quantity, onQuantityChanged } = this.props;
        if (subscription == undefined) return;

        return (
            <div block="SubscriptionEdit" elem="SubscriptionProfile">
                <h3>{__('Subscription Profile')}</h3>
                <table>
                    <tbody>
                        <tr>
                            <th>{__('Profile Number')}</th>
                            <td>{subscription.profile_id}</td>
                        </tr>
                        <tr>
                            <th>{__('Profile Status')}</th>
                            <td>{subscription.subscription_status == 1 ? __('Active') : __('-')}</td>
                        </tr>
                        <tr>
                            <th>{__('Product Name')}</th>
                            <td>{subscription.product_name}</td>
                        </tr>
                        <tr>
                            <th>{__('Quantity')}</th>
                            <td>
                                <Field
                                    id="QuantityInput"
                                    type={NUMBER_TYPE}
                                    value={quantity}
                                    onChange={onQuantityChanged}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }

    renderSubscriptionPaymentDetails() {
        const { subscription, getPrice } = this.props;
        if (subscription == undefined) return

        return (
            <div block="SubscriptionEdit" elem="SubscriptionPaymentDetails">
                <h3>{__('Subscription Payment Details')}</h3>
                <table>
                    <tbody>
                        <tr>
                            <th>{__('Billing Price')}</th>
                            <td>{getPrice()}</td>
                        </tr>
                        <tr>
                            <th>{__('Payment Method')}</th>
                            <td>{subscription.payment_title}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }

    renderSubscriptionHistory() {
        const { subscription, prepareBillingPeriodSelectOptions, billingPeriod, onBillingPeriodChanged } = this.props;
        if (subscription == undefined) return

        return (
            <div block="SubscriptionEdit" elem="SubscriptionHistory">
                <h3>{__('Subscription History')}</h3>
                <table>
                    <tbody>
                        <tr>
                            <th>{__('Subscription Generated On')}</th>
                            <td>{subscription.created_at}</td>
                        </tr>
                        <tr>
                            <th>{__('Next Occurrence Date')}</th>
                            <td>{subscription.next_occurrence_date}</td>
                        </tr>
                        <tr>
                            <th>{__('Billing Period')}</th>
                            <td>
                                <Field
                                    type={SELECT_TYPE}
                                    name="billingPeriod"
                                    value={billingPeriod}
                                    selectOptions={prepareBillingPeriodSelectOptions()}
                                    onChange={onBillingPeriodChanged}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }

    renderOrderInformation() {
        const {
            subscription
         } = this.props;
         if (subscription == undefined) return;
         
        return (
            <div block="SubscriptionEdit" elem="OrderInformation">
                <h3>{__('Order information')}</h3>
                <div>
                    <strong>
                        <span>{__('Billing Address')} #{subscription.billing_address_id}</span>
                    </strong>
                </div>
                <div dangerouslySetInnerHTML={{ __html: subscription.formated_billing_address }}></div>
                <br />
                <div>
                    <strong>
                        <span>{__('Shipping Address')} #{subscription.shipping_address_id}</span>
                    </strong>
                </div>
                <div dangerouslySetInnerHTML={{ __html: subscription.formated_shipping_address }}></div>
            </div>
        );
    }

    render() {
        return (
            <div block="SubscriptionEdit">
                {this.renderContent()}
            </div>
        );
    }
}

export default SubscriptionEditComponent;
