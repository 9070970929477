import { TierPrices as SourceTierPrices } from 'SourceComponent/TierPrices/TierPrices.component';

import './TierPrices.override.style';

export * from 'SourceComponent/TierPrices/TierPrices.component';

/** @namespace HoeksPwa/Component/TierPrices/Component */
export class TierPrices extends SourceTierPrices {

}

export default TierPrices;
