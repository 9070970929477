import { PureComponent } from 'react';
import { connect } from 'react-redux';
import history from 'Util/History';

import MyAccountMySubscriptionProfilesComponent from './MyAccountMySubscriptionProfiles.component';

export const SubscriptionProfileDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    '../../store/SubscriptionProfile/SubscriptionProfile.dispatcher'
);

/** @namespace Hoeks/Subscription/Component/MyAccountMySubscriptionProfiles/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    subscriptionProfiles: state.SubscriptionProfileReducer.subscriptionProfiles.subscriptionProfiles,
    subscription: state.SubscriptionProfileReducer.subscription.subscription,
    isLoading: state.SubscriptionProfileReducer.isLoading
});

/** @namespace Hoeks/Subscription/Component/MyAccountMySubscriptionProfiles/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({
    getSubscriptionProfileData: () => SubscriptionProfileDispatcher.then(
        ({ default: dispatcher }) => dispatcher.getSubscriptionProfileData(_dispatch)
    ),
    getSubscriptionData: (subscription_id) => SubscriptionProfileDispatcher.then(
        ({ default: dispatcher }) => dispatcher.getSubscriptionData(_dispatch, subscription_id)
    ),
});

/** @namespace Hoeks/Subscription/Component/MyAccountMySubscriptionProfiles/Container/MyAccountMySubscriptionProfilesContainer */
export class MyAccountMySubscriptionProfilesContainer extends PureComponent {
    static propTypes = {
        // TODO: implement prop-types
    };

    state = {
        currentPage: 'list',
        currentSubscriptionId: 0
    }

    containerFunctions = {
        // getData: this.getData.bind(this)
        onViewClicked: this.onViewClicked.bind(this),
        onEditClicked: this.onEditClicked.bind(this),
        onBackClicked: this.onBackClicked.bind(this),
        changePage: this.changePage.bind(this)
    };    

    containerProps = () => {
        // isDisabled: this._getIsDisabled()
    };

    componentDidUpdate() {

    }    

    componentDidMount() {
        const { getSubscriptionProfileData, isLoading } = this.props
        this.setState({currentPage: this.getPage()});
        getSubscriptionProfileData();
        this.addOnPopStateEventListner();
    }

    componentDidUpdate() {
        const { isLoading } = this.props;
    }

    getPage() {
        if (window.location.toString().indexOf("view") > -1) {
            return 'view';
        }

        if (window.location.toString().indexOf("edit") > -1) {
            return 'edit'
        }

        return 'list';
    }

    addOnPopStateEventListner() {
        window.onpopstate = (event) => {
            this.setState({currentPage: this.getPage()});
        }
    }

    onViewClicked(selectedSubscription) {
        const { getSubscriptionData, subscription } = this.props;
        
        // getSubscriptionData(selectedSubscription.subscription_id);
        this.setState({currentPage: "view"});
    }

    onEditClicked(subscriptionId) {
        if(confirm(__('Are you sure ? You want to edit this subscription profile.')) == false) {
            window.location = `/my-account/my-subscriptions/view?id=${subscriptionId}`;
            return;
        }
        this.setState({currentPage: "edit"});
    }

    onBackClicked() {
        const { currentPage } = this.state
        this.setState({currentPage: currentPage == 'edit' ? 'view' : 'list'});
        // history.goBack();
    }

    changePage(page) {
        this.setState({currentPage: page});
    }

    render() {
        return (
            <MyAccountMySubscriptionProfilesComponent
              { ...this.props }
              { ...this.state }
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountMySubscriptionProfilesContainer);
