import { ProductWishlistButton as SourceProductWishlistButton } from 'SourceComponent/ProductWishlistButton/ProductWishlistButton.component';

import './ProductWishlistButton.override.style';

export * from 'SourceComponent/ProductWishlistButton/ProductWishlistButton.component';

/** @namespace HoeksPwa/Component/ProductWishlistButton/Component */
export class ProductWishlistButton extends SourceProductWishlistButton {

}

export default ProductWishlistButton;
