/* eslint-disable */
import { connect } from 'react-redux';

const selectShippingMethod = (args, callback, instance) => {
    const delay = 50;

    const scrollToParcelshopPicker = (tries = 0) => {
        const { isMobile } = instance.props;

        if (!isMobile) {
            return;
        }

        if (tries > 4) {
            return;
        }

        const elements = document.getElementsByClassName('ParcelshopPicker');
        if (elements.length > 0) {
            const [parcelshopPicker] = elements;
            parcelshopPicker.scrollIntoView({ behavior: 'smooth' });
        } else {
            setTimeout(scrollToParcelshopPicker, delay, tries + 1);
        }
    }

    setTimeout(scrollToParcelshopPicker, delay);

    return callback.apply(instance, args);
}

const checkoutDeliveryOptionsContainer = (Class) => {
    const mapStateToProps = (state) => ({
        isMobile: state.ConfigReducer.device.isMobile
    })

    const mapDispatchToProps = (dispatch) => ({})

    return connect(mapStateToProps, mapDispatchToProps)(Class);
}

export default {
    'Component/CheckoutDeliveryOptions/Container': {
        'class': {
            position: 10,
            implementation: checkoutDeliveryOptionsContainer
        },
        'member-function': {
            selectShippingMethod
        }
    }
}